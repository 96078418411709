<template>
  <section>

    <transition name="fade">
      <div v-show="scrollArrow" class="container" ref=scrollArrow>
        <div class="chevron"></div>
        <div class="chevron"></div>
        <div class="chevron"></div>
      </div>
    </transition>

    <div
      v-for="(maxim, index) in maxims" :key="index"
      :ref="'maxim'"
      class="maxim"
    >
      <p v-html="maxim.text.replace(/\n/g,'<br>')"></p>
      <span>Robert Iwanowski</span>
    </div>

    <div
      v-if="maxims.length == 0"
    >
      <p class="error">Dział jest pusty lub nie ma takiej tematyki maksym.</p>
    </div>
    
  </section>
</template>

<script>

export default {
  name: 'MaximsBox',
  props: ['posY'],
  data: () => ({
    scrollArrow: false,
    scrollArrowPosition: 0,
    showedMaxims: 0
  }),
  computed: {
    theme () {
      return this.$route.params.theme
    },
    newestOptions () {
      return this.$store.state.data.poems.newest
    },
    maxims () {
      return this.$store.state.maxims.filter(el => {
        return this.theme === this.$store.state.data.maxims.newest.link ? el.newest === true : el.link === this.theme
      })
    }
  },
  methods: {
    checkMaxim () {
      if (this.maxims.length > 0) {
        this.$refs.maxim.forEach(el => {
          if (this.posY >= el.offsetTop - window.innerHeight / 2) {
            this.showMaxim(el)
          }
        })
        this.checkScrollArrow()
      }
    },
    showMaxim (el) {
      if (el.style.opacity == 0) {
        el.style.opacity = 1
        el.style.transform = 'translateX(0)'
        if (this.scrollArrowPosition < el.offsetTop) {
          this.scrollArrowPosition = el.offsetTop
        }
        this.showedMaxims = this.showedMaxims + 1
      }
    },
    initialMaxims () {
      if (this.maxims.length > 0) {
        for (let i = 0; i < 4; i++) {
          this.maxims.length > i ? this.showMaxim(this.$refs.maxim[i]) : null
        }
      }
    },
    checkScrollArrow () {
      if (this.maxims.length == this.showedMaxims) {
        this.scrollArrow = false
      }
      else if ((this.scrollArrowPosition - window.innerHeight / 2 > 0) && (this.posY >= this.scrollArrowPosition - window.innerHeight / 2)) {
        this.scrollArrow = true
      }
      else {
        this.scrollArrow = false
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.initialMaxims()
    }, 500);
    
  },
  created () {
    setTimeout(() => {
      window.addEventListener('scroll', this.checkMaxim)
    }, 500);
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkMaxim)
  }
}
</script>

<style scoped lang="scss">

section {
  @media screen and (min-width: 900px) {
    width: 900px;
  }
  .maxim {
    max-width: 500px;
    padding: 25px;
    background-color: white;
    border: 6px outset #EB7054;
    margin: 50px 0;
    text-align: center;
    font-size: 1.4rem;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 1.5s;
    &:nth-of-type(odd) {
        margin: 50px 0 50px auto;
        transform: translateX(50%);
    }
    @media screen and (min-width: 500px) {
      font-size: 1.6rem;
    }
    span {
        display: block;
        font-size: 0.7em;
        font-style: italic;
        padding-top: 25px;
        text-align: right;
        color: #333;
    }
  }
}
.error {
  color: red;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 15px;
}

.container {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 3;
}

.chevron {
  position: absolute;
  bottom: 75px;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
 content: '';
 position: absolute;
 top: 0;
 height: 100%;
 width: 50%;
 background: #EB7054;
}

.chevron:before {
 left: 0;
 transform: skewY(30deg);
}

.chevron:after {
 right: 0;
 width: 50%;
 transform: skewY(-30deg);
}

@keyframes move-chevron {
 25% {
  opacity: 1;
	}
 33.3% {
  opacity: 1;
  transform: translateY(2.28rem);
 }
 66.6% {
  opacity: 1;
  transform: translateY(3.12rem);
 }
 100% {
  opacity: 0;
  transform: translateY(4.8rem) scale(0.5);
 }
}

</style>
